<nav
  [ngClass]="{
    'bg-white': isCollapsed === false,
    'navbar-transparent': isCollapsed === true,
  }"
  class="navbar navbar-expand-lg navbar-absolute"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <button
          (click)="minimizeSidebar()"
          class="minimize-sidebar btn btn-link btn-just-icon"
          placement="right"
          tooltil="Sidebar toggle"
        >
          <i class="tim-icons icon-align-center visible-on-sidebar-regular">
          </i>
          <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"> </i>
        </button>
      </div>
      <div class="navbar-toggle d-inline">
        <button (click)="sidebarOpen()" class="navbar-toggler" type="button">
          <span class="navbar-toggler-bar bar1"> </span>
          <span class="navbar-toggler-bar bar2"> </span>
          <span class="navbar-toggler-bar bar3"> </span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)"> Dashboard </a>
    </div>

    <button
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-label="Toggle navigation"
      class="navbar-toggler"
      id="navigation"
      type="button"
    >
      <span class="navbar-toggler-bar navbar-kebab"> </span>
      <span class="navbar-toggler-bar navbar-kebab"> </span>
      <span class="navbar-toggler-bar navbar-kebab"> </span>
    </button>

    <div [collapse]="isCollapsed" class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <!--Start Notification-->
        <li
          *ngIf="authRole(['SUPER_ADMIN', 'ADMIN'])"
          class="dropdown nav-item cursor-pointer"
          dropdown
          placement="bottom left"
        >
          <a (click)="openNotificationModal()" class="nav-link">
            <span class="badge badge-danger">{{ numberOfNotifications }}</span>
            <div class="photo">
              <img
                alt="notification"
                height="25px"
                src="../../../../assets/img/svg/alarm.svg"
              />
            </div>
          </a>
        </li>
        <!--End notification-->
        <li class="dropdown nav-item" dropdown placement="bottom left">
          <a
            (click)="$event.preventDefault()"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            dropdownToggle
            href="#"
          >
            <div class="photo">
              <img
                *ngIf="this.auth.user?.picture"
                [src]="urlBaseFiles + this.auth.user?.picture"
                alt="Profile Photo"
              />
              <img
                *ngIf="!this.auth.user?.picture"
                alt="Profile Photo"
                src="https://crm-info.s3.amazonaws.com/crm-bmf/images-crm/perfil.png"
              />
            </div>
            <b class="caret d-none d-lg-block d-xl-block"> </b>
            <p class="d-lg-none">Log out</p>
          </a>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-navbar dropdown-menu-right"
          >
            <li class="nav-link">
              <a
                [routerLink]="['/users/profile']"
                class="nav-item dropdown-item"
              >
                Profile
              </a>
            </li>
            <li class="dropdown-divider"></li>
            <li class="nav-link">
              <a (click)="disconnect()" class="nav-item dropdown-item">
                Log out
              </a>
            </li>
          </ul>
        </li>
        <li class="separator d-lg-none"></li>
      </ul>
    </div>
  </div>
</nav>

<div
  #notificationModal="bs-modal"
  bsModal
  class="modal fade modal-navbar"
  id="deleteBank"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Notification</strong></h5>
        <button (click)="notificationModal.hide()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-4">
          <div
            *ngFor="let notification of notifications"
            class="col-md-12 text-center mt-4"
          >
            <h5>{{ notification.title }}</h5>
            <span class="text-black-50">
              Check the deal to view details of funded.
            </span>
            <br />
            <span style="text-align: center">
              {{ notification.message }}
            </span>
            <br />
            <button
              (click)="updateNotificationDay(notification._id)"
              class="cursor-pointer btn btn-sm btn-primary"
            >
              view
            </button>
            <hr />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          (click)="notificationModal.hide()"
          [disabled]="deleting"
          class="btn btn-sm btn-default"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
