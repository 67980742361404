import { Inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationDayModel } from '@core/models/notification-day.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getNotificationDay() {
    return this.http.get<{ message: string; data: NotificationDayModel[] }>(
      `${environment.API_URI}/notifications/day`,
    );
  }

  updateNotificationDay(data: { notificationId: string }) {
    return this.http.patch<{ message: string; data: any }>(
      `${environment.API_URI}/notifications`,
      data,
    );
  }
}
