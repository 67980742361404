import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  SignatureDeleteModel,
  SignatureUpdateModel,
  AddFundedInterface,
  RecordsInterface,
} from '@core/models';
import { AuthService } from './auth.service';
import { CreateCompany } from '@companies/interfaces';
import { createFormData } from '../../app/utils';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AuthService) private authService: AuthService,
  ) {}

  getCompanies(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams()
        .set('skip', query.skip.toString())
        .set('limit', query.limit.toString());

      if (query.companyName !== '') {
        params = params.set('companyName', query.companyName);
      }
      if (query.dba !== '') {
        params = params.set('dba', query.dba);
      }
      if (query.taxId !== '') {
        params = params.set('taxId', query.taxId);
      }
      if (query.agentId !== '') {
        params = params.set('agentId', query.agentId);
      }

      options = { params };
    }

    return this.http.get(`${environment.API_URI}/companies/all`, options);
  }

  getCompaniesByTaxId(taxId: string) {
    return this.http.get(`${environment.API_URI}/companies/${taxId}`);
  }

  getMembersWithBirthdayThisMonth(filters: any) {
    const query = createFormData(filters);
    const headers = new HttpHeaders({
      Authorization: this.authService.getToken().token,
      'Content-Type': 'application/json',
    });
    return this.http.get(
      `${environment.API_URI}/companies/birthdate?${query}`,
      {
        headers,
      },
    );
  }

  getCompaniesByName(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams().set('companyName', query.companyName);

      if (query.companyName !== '') {
        params = params.set('companyName', query.companyName);
      }

      options = { params };
    }
    return this.http.get(
      `${environment.API_URI}/companies/search/name`,
      options,
    );
  }

  getBlackList(query: { blacklist: boolean }) {
    let options = {};
    if (query) {
      let params = new HttpParams();
      if (query.blacklist) {
        params = params.set('blacklist', query.blacklist.toString());
      }
      options = { params };
    }

    return this.http.get(`${environment.API_URI}/companies/all`, options);
  }

  deleteToBlacklist(id: string) {
    const idEnv = { id };
    const headers = new HttpHeaders({
      Authorization: this.authService.getToken().token,
      'Content-Type': 'application/json',
    });
    return this.http.put(`${environment.API_URI}/companies`, idEnv, {
      headers,
    });
  }

  getCompany(companyId: string, blackList?: boolean) {
    let options = {};

    if (blackList) {
      let params = new HttpParams();
      if (blackList) {
        params = params.set('isBlacklisted', blackList);
      }
      options = { params };
    }
    return this.http.get<{
      data: { company: CreateCompany; records: RecordsInterface[] };
      message: string;
    }>(`${environment.API_URI}/companies?companyId=${companyId}`, options);
  }

  updateFile(file: FormData) {
    const headers = new HttpHeaders({
      Authorization: this.authService.getToken().token,
    });
    return this.http.patch(
      `${environment.API_URI}/companies/upload/other-documents`,
      file,
      { headers },
    );
  }

  downloadFile(
    companyId: string,
    fileId: string,
  ): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/pdf');

    const params = {
      companyId,
      fileId,
    };

    return this.http.get(`${environment.API_URI}/companies/download/file`, {
      headers,
      observe: 'response',
      responseType: 'blob',
      params,
    });
  }

  downloadSignatureFile(companyId: string, memberId: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/png');

    const params = {
      companyId,
      memberId,
    };

    return this.http.get(
      `${environment.API_URI}/companies/download/file/signature`,
      {
        headers,
        observe: 'response',
        responseType: 'blob',
        params,
      },
    );
  }

  deleteFile(payload: {
    companyId: string;
    path: string;
    fileRouteId: string;
  }) {
    const headers = new HttpHeaders({
      Authorization: this.authService.getToken().token,
    });

    return this.http.patch(
      `${environment.API_URI}/companies/delete/other-documents`,
      payload,
      { headers },
    );
  }

  createCompany(company: CreateCompany) {
    company.informationCompany.lengthOwnership =
      company.informationCompany.lengthOwnership.toString();
    return this.http.post(`${environment.API_URI}/companies`, company);
  }

  updateCompany(company: CreateCompany) {
    if (company?.informationCompany?.lengthOwnership) {
      company.informationCompany.lengthOwnership =
        company?.informationCompany?.lengthOwnership.toString();
    }
    return this.http.patch(`${environment.API_URI}/companies`, company);
  }

  getDeal(companyId: string) {
    const params = new HttpParams().set('companyId', companyId);
    return this.http.get(`${environment.API_URI}/companies/information-deal`, {
      params,
    });
  }

  updateSignature(payload: SignatureUpdateModel) {
    return this.http.patch(
      `${environment.API_URI}/companies/upload/signature`,
      payload,
    );
  }

  deleteSignature(payload: SignatureDeleteModel) {
    return this.http.patch(
      `${environment.API_URI}/companies/delete/signature`,
      payload,
    );
  }

  addFundedCompany(payload: AddFundedInterface) {
    return this.http.patch(`${environment.API_URI}/companies/funded`, payload);
  }
}
